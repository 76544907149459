export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'orderCode',
    label: 'field.orderCode',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
  },
  {
    key: 'regionCode',
    label: 'field.regionCode',
  },
  {
    key: 'agentCode',
    label: 'field.agentCode',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    label: 'field.orderedDate',
    type: 'datetime',
    hideToggle: true,
  },
  {
    key: 'expectDeliveryDate',
    label: 'field.expectDeliveryDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'salespersonName',
    label: 'field.seName',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'outletName',
    label: 'field.outletName',
    localization: true,
    subKey: 'outletCode',
    hideToggle: true,
  },
  {
    key: 'outletSegmentName',
    label: 'field.outletSegment',
    localization: true,
  },
  {
    key: 'productName',
    label: 'field.product',
    localization: true,
    subKey: 'productCode',
    hideToggle: true,
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    hideToggle: true,
  },
  {
    key: 'state',
    label: 'field.state',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'isOnRoute',
    label: 'field.type',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'orderChannel',
    label: 'field.channel',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
];
